<template>
  <div>
    <ClientsCRUD />
    <SuppliersTable class="mt-8" />
  </div>
</template>

<script>
import ClientsCRUD from "@/modules/evaluator/views/ClientsCRUD.vue";
import SuppliersTable from "@/modules/evaluator/components/clients/SuppliersTable.vue";

export default {
  components: {
    ClientsCRUD,
    SuppliersTable,
  },
};
</script>
